.App {
  text-align: center;
}

.App-header {
  background-color: #FFFFFF;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: rgb(34, 33, 33);
}

.App-link {
  color: #61dafb;
}
